import React, { useState } from 'react';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import Banner from '../components/layout/partials/Banner';
import SmoothScroll from '../components/elements/SmoothScroll';

const LayoutDefault = ({ children }) => {

  const [theme, setTheme] = useState('light');

  const changeTheme = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }

  return (
    <div className={`${theme}`}>
      <Header navPosition="left" className="" theme={theme} changeTheme={changeTheme}/>
      <main className="site-content">
        {children}
      </main>
      <Footer />
    </div>
    );
}

export default LayoutDefault;  