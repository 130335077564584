export const navigateTo = (e) => {
    const link = e.target.href
    const splitLink = link.split('#')
    const id = splitLink[1]

    setTimeout(() => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }, 0);
  }