import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import i18next from 'i18next';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const Testimonial = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: i18next.t('clients.title'),
    paragraph: ''
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right mb-5 center-content" data-reveal-delay="200">
              <Image
                className=''
                src={require('./../../assets/images/saudi-customs.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-bottom mb-5 center-content">
            <Image
                className='w-50'
                src={require('./../../assets/images/king-khaled-uni.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-left mb-5 center-content" data-reveal-delay="200">
            <Image
                className='w-50'
                src={require('./../../assets/images/ministry-defense.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-right mb-5 center-content" data-reveal-delay="200">
              <Image
                className='w-50'
                src={require('./../../assets/images/anb.png')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-bottom mb-5 center-content">
            <Image
                className='w-50'
                src={require('./../../assets/images/t-d-f.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-left mb-5 center-content" data-reveal-delay="200">
            <Image
                className='w-50'
                src={require('./../../assets/images/rajhi.png')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-right mb-5 center-content" data-reveal-delay="200">
              <Image
                className='w-50'
                src={require('./../../assets/images/alinma.png')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-bottom mb-5 center-content">
            <Image
                className='w-50'
                src={require('./../../assets/images/solutions.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-left mb-5 center-content" data-reveal-delay="200">
            <Image
                className='w-50'
                src={require('./../../assets/images/stc.png')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-right mb-5 center-content" data-reveal-delay="200">
              <Image
                className='w-50'
                src={require('./../../assets/images/red-sea.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-bottom mb-5 center-content">
            <Image
                className='w-50'
                src={require('./../../assets/images/snb.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-left mb-5 center-content" data-reveal-delay="200">
            <Image
                className='w-50'
                src={require('./../../assets/images/mada.png')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-right mb-5 center-content" data-reveal-delay="200">
              <Image
                className='w-50'
                src={require('./../../assets/images/saudi-payments.png')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-bottom mb-5 center-content">
            <Image
                src={require('./../../assets/images/tabouk-uni.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-left mb-5 center-content" data-reveal-delay="200">
            <Image
                className='w-50'
                src={require('./../../assets/images/taif-uni.png')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-right mb-5 center-content" data-reveal-delay="200">
              <Image
                className='w-50'
                src={require('./../../assets/images/ministry-water-electercity.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-bottom mb-5 center-content">
            <Image
                className='w-50'
                src={require('./../../assets/images/internal-ministry.jpg')}
                alt="Features split 03"
                />
            </div>

            <div className="tiles-item reveal-from-left mb-5 center-content" data-reveal-delay="200">
            <Image
                className='w-50'
                src={require('./../../assets/images/sabb.jpg')}
                alt="Features split 03"
                />
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

Testimonial.propTypes = propTypes;
Testimonial.defaultProps = defaultProps;

export default Testimonial;