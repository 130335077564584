const en = {
    header: {
        home: 'Home',
        about: 'About Us',
        goals: 'Our Goals',
        services: 'Our Services',
        team: 'Team Work',
        contact: 'Contact Us'
    },
    hero: {
        title: 'We aim to be leaders in the field of',
        highlight: 'technology, driven by our ambition.',
        desc: 'Our ambition leads us towards excellence and leadership. We provide our customers with unique and innovative solutions in developing programs, mobile applications and digital platforms, technical support, e-marketing, e-commerce, web design and website development.',
    },
    about: {
        title: 'Who we are',
        desc: `It’s company specializes in a cable distributer, security, safety and control systems
        integrator. We provide cable, security, safety, control solutions & audio video
        solutions to industrial, governmental, residential, health, and educational facilities.
        Its scope of services covers where Kingdom of Saudi Arabia through one office at
        central Region in Riyadh City.`,
        saudi_vision: 'We work according to the 2030 vision of creativity and innovation in the field of information technology',
        more_info: 'We offer a wide range of our digital solutions in many fields through diverse and flexible business models that suit the desire of our customers according to what fulfills their desires and achieves their ambitions, we seek to provide high-quality and international technical solutions and designs, with great user experience, ranging from mobile and web applications to more complex projects',
        our_message: 'Our message',
        desc_message: `Since its inception It’s has built its reputation on quality products with unparalleled
        service to customers. That service begins in the corporate office and depends on
        each employee doing his job correctly and promptly.`
    },
    goals: {
        title: 'Our Goals',
        p1: 'Raise the general level of technical solutions in the local and global market.',
        p2: 'Take advantage of all available resources in order to integrate technical solutions.',
        p3: 'Adding to clients and developing and presenting business through the web.',
        p4: 'To be pioneers in the field of technology leads us to our ambition towards excellence and increase.',
        p5: 'Achieving the highest quality standards in all our services and products.'
    },
    services: {
        title: 'Services we offer for you',
        desc: 'Choose the type of services that suit you below',
        development_and_marketing: 'Show software services and digital marketing',
        light_current_systems: 'Show light current systems services',
        s1: 'Design for the user',
        p1: 'We create user interfaces that are professional and easy to use.',
        s2: 'Development of applications and systems',
        p2: 'We develop applications of the highest quality and serve modern systems.',
        s3: 'Digital Marketing',
        p3: 'We use creative content and paid advertising to increase awareness of your product and brand',
        s4: 'Creativity and innovation',
        p4: 'We provide design services for digital marketing, logo design, static and mobile advertising designs',
        s5: '24/7 technical support',
        p5: 'We provide technical support services in several distinct ways in order to ensure a quick response to all your technical problems',
        l1: 'Cable Solutions',
        pl1: `It’s now able to offer it’s clients cable solution. Our company offers fast and
        flexible comprehensive solutions also Network Devices and infrastructure`,
        l2: 'CCTV System',
        pl2: `It’s designs and installs simple and complex CCTV systems using a wide range
        of CCTV products.
        There are integrated solutions at different prices commensurate with the size
        of the project and customer request, including: shops and houses - factories
        and major exhibitions - construction companies and buildings - government
        agencies and public institutions and bodies.`,
        l3: 'Fire Alarm System',
        pl3: `It’s represents a range of fire detection products manufactured by best fire
        alarm systems companies, we have best complete solutions whatever the
        requirements are from the smallest conventional system up to the largest fully
        integrated solution; We have a solution to meet our client’s needs.`,
        l4: 'Access Control Systems',
        pl4: `Access control systems are security devices that monitor and control entry to a
        house, apartment, or a building. Because keys are easily duplicated, these systems
        are the best way to keep track of who is entering your area. Companies requiring
        high levels of security and access to certain areas may now better manage and
        monitor protection and quickly respond to security breaches by utilizing DVI Access
        Control Systems.`,
        l5: 'PABX System',
        pl5: `Is a remote connection system used in the public network for telephone or large
        enterprise transformation.
        The PABX consists of electronic components. In older systems, the human
        element was used (as an extension) to connect telephone lines of subscribers
        or circuits in digital systems to regulate telephone calls between subscribers.`,
        l6: '(MATV) System',
        pl6: `It’s represents a full range of Master Antenna Television (MATV) Products
        manufactured by best companies, and we have a god experience in the field
        of design, manufacturing, and marketing of products for the reception and
        distribution of TV signals.`,
        l7: 'IP TV System',
        pl7: `Complete remote control of the systems without needing a dedicated PC,
        Bandwidth optimization, streaming the modules only upon the customer’s
        request, Programs and services delivered as multicast streams, Very easy and
        user-friendly system set-up.`,
        l8: 'Nurse Call Systems',
        pl8: `The Aid Call Wireless Nurse Call System is designed to visually & audibly alert
        nursing staff to calls from patients & other members of staff. Because the Aid Call
        Wireless Nurse Call System is radio based it is infinitely expandable depending
        on your requirements and with none of the traditional constraints and problems
        caused by a hard wired system.
        Aid Call Wireless Nurse Call system is safe, reliable and proven with over 3,000
        systems installed nationwide, offering fast and easy installation and at fraction
        of the cost of a wired system because the system is radio based, it is infinitely
        changeable and expandable, allowing for the constant ability to deal quickly with
        the ever changing clinical priorities and demands`,
        l9: 'Gate Barriers and gate access systems',
        pl9: `Barrier gates are generally used in parking lots and garages. They are also used
        in many commercial applications such as airports and public facilities. These units
        can be operated automatically by card reader, keypad, telephone entry system,
        or manually controlled. There are different types with different shapes of such
        gates like the durable remote control barrier system and the customized gate
        barrier system.
        Gate access system: One of the simplest ways to protect your business is to
        control the flow of movement in and out of your buildings. AAC can design an
        access control system that fits in with your corporate image, from basic sign-in or
        turnstile facilities to a sophisticated multi-level authorization card or lock system
        that can track and restrict access for different categories of personnel or visitor
        at various key points around your premises.`,
        l10: 'Home Automation Systems',
        pl10: `Home automation security system is a specialized field dealing with specified
        requirements of homes in the usage of easy-to-use technologies for security
        and comforts of the residents. Home automation includes all that a building
        automation provides like the climate controls, door and window controls and
        security etc plus additionally controlling multimedia home theaters, pet
        feeding, plant watering, blinds and so on. But there exists a difference in that home
        automation emphasizes more on comforts through ergonomics and ease of
        operation. With the fast development in the field, door ways for wireless home
        automation system is being opened in a big way.`,
        l11: 'A Master Clock System',
        pl11: `A master clock is a precision clock that provides timing signals to synchronize
        slave clocks as part of a clock network.
        The master clock in such installations is controlled by an accurate quartz crystal
        oscillator, usually referenced to an external frequency standard such as MSE,
        which is part of a world-wide timekeeping system.`,
        l12: 'Public Address System',
        pl12: `We have a large list of references of projects in Saudi Arabia that we designed,
        installed and maintain. It includes Airports, Hotels, Universities, Hospitals,
        Mosques, Churches, Conference Rooms etc..`,
        l13: 'A/V Control System',
        pl13: `AV Control System: provides an intuitive interface in the form of a touch screen
        or button panel, to allow the user to control all elements of the AV system from
        a single location, rather than controlling each device independently with multiple
        remote controls.`,
        l14: 'DISPLAY SYSTEMS',
        pl14: `The total system, combining hardware and software, needed to achieve a visible
        representation of information in a data-processing system.`,
        l15: 'Audio Conference System',
        pl15: `Audio conferencing usually refers to meetings held by people in different
        places using devices that allow sounds to be sent and received. These meetings may
        only involve two parties, but in many cases, there are numerous parties involved.
        Audio conferencing allows multiple parties to connect using devices such as
        phones or computers.`,
        l16: 'Video Conference System',
        pl16: `video conference by a set of telecommunication technologies which allow
        two or more locations to communicate by simultaneous two-way video and
        audio transmissions. It uses audio and video telecommunications to bring
        people at different sites together people in private offices (point-to-point) or
        involve several (multipoint) sites in large rooms at multiple locations.`,
        l17: 'Video Wall',
        pl17: `A video wall consists of multiple computer monitors, video projectors, or
        television sets tiled together contiguously or overlapped to form one large
        screen. Typical display technologies include LCD panels, LED arrays, DLP tiles,
        and rear projection screens.`,
        l18: 'Digital Signage',
        pl18: `Digital signage is a form of electronic display that shows television programming,
        menus, information, advertising, and other messages.
        Digital signs (frequently utilizing technologies such as LCD, LED, plasma
        displays, or images to display content) can be found in both public and private
        environments, including retail stores, hotels, restaurants, and corporate buildings,
        amongst other locations.`,
        l19: 'Studio System',
        pl19: `System Studio is a high performance, model-based signal processing algorithm
        design and analysis tool. It combines the industry’s best simulation performance
        with high modeling efficiency plus seamless integration into chip implementation
        design and verification flows. Leading communication and multimedia companies
        rely on Synopsys’ System Studio and DSP model libraries to address their
        system-level design needs by getting to results faster.`
    },
    clients: {
        title: 'Our Clients',
        desc: 'Our company assists any customers at any phase of business or software development — from startups at the ideation stage to mid-sized businesses and large enterprises with a focus on optimization and expansion no matter the industry they operate in',
    },
    stats: {
        title: 'Experienced experts are giving advices.',
        desc: 'We delivered a platform to collect user behavior information, build predictive models and help making informed business decisions.',
        p1: 'Customers visit us every month to get their service done via our website.',
        p2: 'Satisfaction rate comes from our awesome customers.',
        p3: 'Average customer ratings we have.',
        m: '+100k',
    },
    teamwork: {
        title: 'Team Work',
        desc: 'A team with great experience in the field of programming and design, we have a passionate team specialized in most technical and engineering fields, a team consisting of:',
        p1: 'Web developers',
        p2: 'Quality officials',
        p3: 'User Interface Developers',
        p4: 'UI Designers',
        p5: 'Analysis officials',
        p6: 'Full team support and monitoring'
    },
    contact: {
        title: 'Contact us',
        desc: "Your questions and comments are important to us, We would be happy to answer your questions or you can reach us by phone or email.\n We've got everything covered for your needs ",
        visit: 'Visit us',
        address: 'Prince Turkey Al-Awal,\n Riyadh, Saudi Arabia Kingdom',
        email: 'Email us',
        call: 'Call us',
    },
    form: {
        f_l_name: 'First & Last Name',
        f_l_name_h: 'i.e. Muhhamed Ahmed',
        email: 'Email',
        email_h: 'i.e. info@sair.sa',
        mobile: 'Phone Number',
        mobile_h: 'i.e. +966567890123',
        subj: 'Subject',
        subj_h: 'i.e. Question about ...',
        msg: 'Message',
        msg_h: 'i.e message details ...',
        send: 'Send',
        required: 'Required',
        success: 'The email has been sent successfully',
        error: 'There is an error!'
    },
    footer: {
        desc: 'Security Option Company is a management and technology consulting company that helps companies make improvements through technology– especially in the area of business management software.',
        comp: 'Company',
        about: 'About us',
        contact: 'Contact us',
        carrers: 'Careers',
        press: 'Press',
        product: 'Product',
        features: 'Features',
        pricing: 'Pricing',
        news: 'News',
        help: 'Help desk',
        support: 'Support',
        services: 'Services',
        digital: 'Digital Marketing',
        content: 'Content Writing',
        seo: 'SEO for Business',
        ui: 'UI Design',
        legal: 'Legal',
        privacy: 'Privacy Policy',
        terms: 'Terms & Conditions',
        return: 'Return Policy',
    }
}

export default en;