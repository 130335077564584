import React from 'react';
// import sections
import Hero from '../components/sections/Hero';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import Goals from '../components/sections/Goals';
import Teamwork from '../components/sections/Teamwork';
import { Toaster } from 'react-hot-toast';

const Home = () => {

  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesTiles />
      <Goals topDivider />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <Teamwork topDivider />
      <Testimonial topDivider />
      <Cta split topDivider />
      <Toaster position='top-right' toastOptions={{ className: 'react-hot-toast' }} />
    </>
  );
}

export default Home;