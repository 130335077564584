import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import i18next from 'i18next'
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import SoftwareServices from './partials/services/SoftwareServices';
import LightCurrentServices from './partials/services/LightCurrentServices';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: i18next.t('services.title'),
    paragraph: i18next.t('services.desc'),
  };

  const [activeService, setActiveService] = useState('software')

  const switchServices = (e) => {
    const service = e.target.id
    if (service === 'software' )
      setActiveService('software')
    else 
      setActiveService('light_current')
  }

  return (
    <section
      {...props}
      className={outerClasses}
      id='services'
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content services-header" />
          <nav className='mb-5'>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button className="nav-link active" id="nav-development-tab" data-bs-toggle="tab" data-bs-target="#nav-development" type="button" role="tab" aria-controls="nav-development" aria-selected="true">{i18next.t('services.development_and_marketing')}</button>
              <button className="nav-link" id="nav-light-current-tab" data-bs-toggle="tab" data-bs-target="#nav-light-current" type="button" role="tab" aria-controls="nav-light-current" aria-selected="false">{i18next.t('services.light_current_systems')}</button>
            </div>
          </nav>
          <div className='tab-content' id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-development" role="tabpanel" aria-labelledby="nav-development-tab">
              <div className={splitClasses}>
                <SoftwareServices imageFill={imageFill}/>
              </div>
            </div>
            <div className="tab-pane fade" id="nav-light-current" role="tabpanel" aria-labelledby="nav-light-current-tab">
              <div className={splitClasses}>
                <LightCurrentServices imageFill={imageFill}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;