import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import i18next from 'i18next'
import {navigateTo} from './../../../utils/scroll'

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <Link to="#home" onClick={(e) => navigateTo(e)}>{i18next.t('header.home')}</Link>
        </li>
        <li>
          <Link to="#about" onClick={(e) => navigateTo(e)}>{i18next.t('header.about')}</Link>
        </li>
        <li>
          <Link to="#goals" onClick={(e) => navigateTo(e)}>{i18next.t('header.goals')}</Link>
        </li>
        <li>
          <Link to="#services" onClick={(e) => navigateTo(e)}>{i18next.t('header.services')}</Link>
        </li>
        <li>
          <Link to="#team" onClick={(e) => navigateTo(e)}>{i18next.t('header.team')}</Link>
        </li>
        <li>
          <Link to="#contact" onClick={(e) => navigateTo(e)}>{i18next.t('header.contact')}</Link>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;