import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import i18next from 'i18next'

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section about',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: i18next.t('about.title'),
    paragraph: i18next.t('about.desc')
  };

  const section2Header = {
    title: i18next.t('about.our_message'),
    paragraph: i18next.t('about.desc_message')
  }

  return (
    <section
      {...props}
      className={outerClasses}
      id='about'
    >

      <img className='saudi-vision-logo' src={require('./../../assets/images/saudi-vision-logo.png')}/>

      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className='reveal-from-bottom mt-4'>
            <SectionHeader data={section2Header} className="center-content" />
          </div>

          <div className='tiles-wrap saudi-vision-text center-content'>
            <div className='tiles-item reveal-from-bottom'>
              <img src={require('./../../assets/images/saudi-vision.png')} alt="" className="saudi-vision" />
            </div>
            <div className='tiles-item reveal-from-bottom'>
              <h4>{i18next.t('about.saudi_vision')}</h4>
            </div>
          </div>

          <div className='tiles-wrap center-content p-2'>
            <div className='reveal-from-bottom'>
              <p>{i18next.t('about.more_info')}</p>
            </div>
          </div>

        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;