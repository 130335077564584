import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Logo from './partials/Logo';
import i18n from '../../langs';
import i18next from 'i18next'
import { navigateTo } from '../../utils/scroll';

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool
}

const defaultProps = {
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  theme,
  changeTheme,
  ...props
}) => {

  const [isActive, setIsactive] = useState(false);
  const [lang, setLang] = useState(i18n.language)
  const [scrolled, setScrolled] = useState('')

  const nav = useRef(null);
  const hamburger = useRef(null);

  useEffect(() => {
    isActive && openMenu();
    document.addEventListener('keydown', keyPress);
    document.addEventListener('click', clickOutside);
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      document.removeEventListener('keydown', keyPress);
      document.removeEventListener('click', clickOutside);
      window.removeEventListener('scroll', handleScroll);
      closeMenu();
    };
  });  

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 20) {
      setScrolled('sticky-site-header')
    }
    else {
      setScrolled('')
    }
  };

  const openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    nav.current.style.maxHeight = nav.current.scrollHeight + 'px';
    setIsactive(true);
  }

  const changeLang = () => {
    if (i18n.language === 'ar') {
      i18n.changeLanguage('en')
      setLang('en')
      document.documentElement.lang = 'en'
      document.documentElement.dir = 'ltr'
    } else {
      i18n.changeLanguage('ar')
      setLang('ar')
      document.documentElement.lang = 'ar'
      document.documentElement.dir = 'rtl'
    }
  }

  const closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    nav.current && (nav.current.style.maxHeight = null);
    setIsactive(false);
  }

  const keyPress = (e) => {
    isActive && e.keyCode === 27 && closeMenu();
  }

  const clickOutside = (e) => {
    if (!nav.current) return
    if (!isActive || nav.current.contains(e.target) || e.target === hamburger.current) return;
    closeMenu();
  }  

  const classes = classNames(
    'site-header',
    bottomOuterDivider && 'has-bottom-divider',
    className,
    scrolled
  );
  
  return (
    <header
      {...props}
      className={classes}
    >
      <div className="container custom-container-nav">
        <div className={
          classNames(
            'site-header-inner',
            bottomDivider && 'has-bottom-divider'
          )}>
          <Logo />
          {!hideNav &&
            <>
              <button
                ref={hamburger}
                className="header-nav-toggle"
                onClick={isActive ? closeMenu : openMenu}
              >
                <span className="screen-reader">Menu</span>
                <span className="hamburger">
                  <span className="hamburger-inner"></span>
                </span>
              </button>
              <nav
                ref={nav}
                className={
                  classNames(
                    'header-nav',
                    isActive && 'is-active'
                  )}>
                <div className="header-nav-inner">
                  <ul className={
                    classNames(
                      'list-reset text-xs',
                      navPosition && `header-nav-${navPosition}`
                    )}>
                    <li>
                      <Link to="#home" onClick={(e) => navigateTo(e)}>{i18next.t('header.home')}</Link>
                    </li>
                    <li>
                      <Link to="#about" onClick={(e) => navigateTo(e)}>{i18next.t('header.about')}</Link>
                    </li>
                    <li>
                      <Link to="#goals" onClick={(e) => navigateTo(e)}>{i18next.t('header.goals')}</Link>
                    </li>
                    <li>
                      <Link to="#services" onClick={(e) => navigateTo(e)}>{i18next.t('header.services')}</Link>
                    </li>
                    <li>
                      <Link to="#team" onClick={(e) => navigateTo(e)}>{i18next.t('header.team')}</Link>
                    </li>
                    <li>
                      <Link to="#contact" onClick={(e) => navigateTo(e)}>{i18next.t('header.contact')}</Link>
                    </li>
                  </ul>
                  {!hideSignin &&
                    <ul
                      className="list-reset header-nav-right"
                    >
                      {theme === 'dark' 
                      ?
                      <li className='center-content'>
                        <img onClick={changeTheme} className="moon-icon" src={require('./../../assets/images/icon-moon.svg')} alt="Moon" />
                      </li>
                      :
                      <li className='center-content'>
                        <svg 
                          onClick={changeTheme}
                          className="sun-icon" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.0908 18.6363C10.3549 18.6363 8.69 17.9467 7.46249 16.7192C6.23497 15.4916 5.54537 13.8268 5.54537 12.0908C5.54537 10.3549 6.23497 8.69 7.46249 7.46249C8.69 6.23497 10.3549 5.54537 12.0908 5.54537C13.8268 5.54537 15.4916 6.23497 16.7192 7.46249C17.9467 8.69 18.6363 10.3549 18.6363 12.0908C18.6363 13.8268 17.9467 15.4916 16.7192 16.7192C15.4916 17.9467 13.8268 18.6363 12.0908 18.6363ZM12.0908 16.4545C13.2481 16.4545 14.358 15.9947 15.1764 15.1764C15.9947 14.358 16.4545 13.2481 16.4545 12.0908C16.4545 10.9335 15.9947 9.8236 15.1764 9.00526C14.358 8.18692 13.2481 7.72718 12.0908 7.72718C10.9335 7.72718 9.8236 8.18692 9.00526 9.00526C8.18692 9.8236 7.72718 10.9335 7.72718 12.0908C7.72718 13.2481 8.18692 14.358 9.00526 15.1764C9.8236 15.9947 10.9335 16.4545 12.0908 16.4545ZM10.9999 0.0908203H13.1817V3.36355H10.9999V0.0908203ZM10.9999 20.8181H13.1817V24.0908H10.9999V20.8181ZM2.83446 4.377L4.377 2.83446L6.69082 5.14828L5.14828 6.69082L2.83446 4.37809V4.377ZM17.4908 19.0334L19.0334 17.4908L21.3472 19.8046L19.8046 21.3472L17.4908 19.0334ZM19.8046 2.83337L21.3472 4.377L19.0334 6.69082L17.4908 5.14828L19.8046 2.83446V2.83337ZM5.14828 17.4908L6.69082 19.0334L4.377 21.3472L2.83446 19.8046L5.14828 17.4908ZM24.0908 10.9999V13.1817H20.8181V10.9999H24.0908ZM3.36355 10.9999V13.1817H0.0908203V10.9999H3.36355Z" fill=""></path>
                        </svg>
                      </li>
                      }
                      <li>
                        <Link className="button button-primary button-wide-mobile button-sm" onClick={changeLang}>{lang === 'ar' ? 'English' : 'العربية'}</Link>
                      </li>
                    </ul>}
                </div>
              </nav>
            </>}
        </div>
      </div>
    </header>
  );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
