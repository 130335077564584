import React from 'react';
import classNames from 'classnames';
import Image from '../../../elements/Image'
import i18next from 'i18next'

const SoftwareServices = ({
    imageFill
}) => {
    return (
        <div>
            <div className="split-item">
                <div className="split-item-content center-content-mobile" data-reveal-container=".split-item">
                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                        
                        </div>
                    <h3 className="mt-0 mb-12">
                        {i18next.t('services.s1')}
                        </h3>
                    <p className="m-0">
                    {i18next.t('services.p1')}
                        </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                    <Image
                        src={require('./../../../../assets/images/ui-ux.jpg')}
                        alt="Features split 01"
                        width={528}
                        height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.s2')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.p2')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/development.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.s3')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.p3')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/marketing.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.s4')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.p4')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/innovation.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.s5')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.p5')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/support.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>
        </div>
    )
}

export default SoftwareServices