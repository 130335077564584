import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Input from '../elements/Input';
import i18next from 'i18next'
import Button from '../elements/Button';
import emailjs from '@emailjs/browser';
import toast from 'react-hot-toast';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner row',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault(); // prevents the page from reloading when you hit “Send”
    
    emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAIL_PUBLIC_KEY)
      .then((result) => {
          // show the user a success message
          toast.success(i18next.t('form.success'))
      }, (error) => {
          // show the user an error
          toast.error(i18next.t('form.error'))
      });
  };

  return (
    <section
      {...props}
      className={outerClasses}
      id='contact'
    >
      <div className="container-fluid">
        <div
          className={innerClasses}
        >
          <div className="cta-slogan col-lg-4 m-0 mb-3">
            <h4>
              {i18next.t('contact.call')}
            </h4>
            <p>
              0534609703
            </p>

            <h4>
              {i18next.t('contact.email')}
            </h4>
            <p>
              info@sac.net.sa
            </p>

            <h4>
              {i18next.t('contact.visit')}
            </h4>
            <p>
              {i18next.t('contact.address')}
            </p>
          </div>
          <div className="cta-action col-lg-7">
            <form ref={form} onSubmit={sendEmail}>
              <div className='form'>
                <label className='label'>{i18next.t('form.f_l_name')} ({i18next.t('form.required')})</label>
                <input id="newsletter" type="text" placeholder="Mohamed Ahmed" name="customer_name" required>
                </input>

                <label className='label'>{i18next.t('form.email')}</label>
                <input id="email" type="email" placeholder="example@gmail.com" name="customer_email" >
                </input>

                <label className='label'>{i18next.t('form.mobile')} ({i18next.t('form.required')})</label>
                <input id="mobile" type="text" placeholder="0512345678" name="customer_phone" required>
                </input>

                <label className='label'>{i18next.t('form.subj')}</label>
                <input id="subject" type="text" placeholder="" name='subject' >
                </input>

                <label className='label'>{i18next.t('form.msg')} ({i18next.t('form.required')})</label>
                <textarea name='message' required />

                <Button type="submit" color="primary" wideMobile>
                  {i18next.t('form.send')}
                </Button>
              </div>
            </form>
          </div>
          
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;