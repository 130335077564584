const ar = {
    header: {
        home: 'الصفحة الرئيسية',
        about: 'من نحن',
        goals: 'أهدافنا',
        services: 'خدماتنا',
        team: 'فريق العمل',
        contact: 'تواصل معنا'
    },
    hero: {
        title: 'نسعى لنكون رواد في مجال التقنية، يقودنا طموحنا نحو',
        highlight: 'التفوق والريادة',
        desc: `\n نقدم لعملائنا حلول فريدة ومبتكرة في 
        \n تطوير البرامج و تطبيقات الموبايل
        \n والمنصات الرقمية ، والدعم الفني ، 
        \n التسويق الإلكتروني، التجارة الالكترونية ،
        تصميم مواقع الإنترنت وتطوير المواقع.`
    },
    about: {
        title: 'من نحن',
        desc: `ﺷﺮﻛﺔ ﻣﺘﺨﺼﺼــﺔ ﺑﻤﺠــﺎل تقنية اﻟﻤﻌﻠﻮﻣﺎت واﻟﺸﺒﻜﺎت السلكية واللاسلكية و
        تجهيز اﻟﻤﺴــﺎرح واﻟﻘﺎﻋــﺎت ﺑﺄﺣــﺪث اﻷﻧﻈﻤــﺔ السمعية والمرئية واﻟﻜﺎﺑﻼت، واﻷﻧﻈﻤﺔ الأمنية لتلبية إحتياجات اﻟﻘﻄﺎﻋــﺎت الحكومية والصناعية والطبية
        وﻛﺬﻟــﻚ التعليمية ﻓــﻲ جميع ﻣﺪن اﻟﻤﻤﻠﻜﺔ ﻣﻦ ﺧﻼل اﻹدارة اﻟﻌﺎﻣﺔ ﺑﺎﻟﺮﻳﺎض.
        `,
        saudi_vision: 'نعمل وفق رؤية 2030 في الإبداع والإبتكار في مجال تقنية المعلومات',
        more_info: `نقدم مجموعة كبيرة من حلولنا الرقمية في العديد من المجالات من خلال نماذج عمل متنوعة ومرنة تتناسب مع رغبة عملائنا وفق ما يحقق رغباتهم ويحقق طموحاتهم, نسعى لتقديم حلول وتصاميم تقنية ذات جودة عالية وعالمية, بتجربة مستخدم رائعة, بدءا من تطبيقات الهواتف والويب إلى مشاريع أكثر تعقيدا`,
        our_message: 'رسالتنا',
        desc_message: `ﻧﺤــﻦ ﻧﻔﺘﺨــﺮ بالجهود اﻟﺘــﻲ نبذلها بتوفير أﻋﻠــﻰ ﻣﺴــﺘﻮى ﻣــﻦ اﻟﺨﺪﻣــﺎت واﻟﺤﻠــﻮل ﻟﻌﻤﻼﺋﻨـﺎ، وﻧﺤـﺎول داﺋﻤـﺎ ﺟﻌـﻞ ﻣﺼﺎﻟـﺢ العميل أوﻟﻮﻳـﺔ وهدف أﺳﺎﺳـﻲ نسير عليه، وﻣﻔﺘــﺎح اﻟﻨﺠــﺎح هو دﻣــﺞ أﻓﻜﺎرﻧــﺎ و ﺧﻄﻄﻨــﺎ
        ﻟﻜــﻲ ﻧﻨﻤــﻮ .ﺟﻨﺒــﺎ ﻋﻠــﻰ ﺟﻨــﺐ ﻣــﻊ ﻋﻤﻼﺋﻨــﺎ ﻧﻈﺎم وﺧﺪﻣﺔ ﻧﺎﺟﺤﻪ داﺋﻤﺎ تنتهي
        ﺑﻘﺼﺔ وهدفنا أن نجعلها ﺗﺤﻔﺔ فنية .
        `
    },
    goals: {
        title: 'أهدافنا',
        p1: 'رفع المستوى العام للحلول التقنية في السوق المحلي و العالمي .',
        p2: 'الاستفادة من كل المصادر المتاحة وذلك لدمج الحلول التقنية .',
        p3: 'الإضافة إلى العملاء وتطوير الأعمال وتقديمها من خلال الويب.',
        p4: 'أن نكون رواد في مجال التقنية يقودنا طموحنا نحو التفوق والزيادة.',
        p5: 'تحقيق أعلى معايير الجودة في جميع خدماتنا ومنتجاتنا.'
    },
    services: {
        title: 'الخدمات التي نقدمها لك',
        desc: 'قم بإختيار نوع الخدمات المناسب لك بالأسفل',
        development_and_marketing: 'عرض خدمات البرمجيات والتسويق الإلكتروني',
        light_current_systems: 'عرض خدمات أنظمة التيار الخفيف',
        s1: 'التصميم للمستخدم',
        p1: 'نقوم بانشاء واجهات مستخدم احترافية وسهلة الاستخدام.',
        s2: 'تطوير التطبيقات والأنظمة',
        p2: 'نقوم بتطوير التطبيقات بأعلى جودة وتخدم النظم الحديثة.',
        s3: 'التسويق الرقمي',
        p3: 'نستخدم المحتوى الإبداعي والإعلانات المدفوعة لزيادة الوعي بمنتجك وعلامتك التجارية',
        s4: 'الإبداع والإبتكار',
        p4: 'نقدم خدمات التصاميم الخاصة بالتسويق الرقمي, تصميم الشعارات, التصاميم الإعلانية الثابتة والمتحركة',
        s5: 'دعم فني تقني 24\/7',
        p5: 'نقدم خدمات الدعم الفني التقني بعدة طرق متميزة وذلك لتضمن سرعة الاستجابة لجميع مشاكلك التقنية',
        l1: 'الكابلات والبنية التحتية',
        pl1: `نحن قادرون على تقديم حلول الكابلات من الشركات العالمية الأكثر خبرة ومصنعي الكيابل الصناعية العالمية وتوفر
        المؤسسة تكنولوجيا سريعة وسهلة ومرنة وتوفير أقوي الشركات العالمية المتخصصة بالاتصالات والشبكات
        والسيرفرات والبنية التحتية مثل سيسكو والكاتل وهواوي وديل وغيرها`,
        l2: 'أنظمة الدوائر التلفزيونية المغلقة (كاميرات المراقبة)',
        pl2: `تصميم وتركيب الأنظمة البسيطة والمعقدة من أنظمة الدوائر التلفزيونية
        المغلقة باستخدام نطاق واسع من منتجات الدوائر التلفزيونية المغلقة .
        يوجد حلول متكاملة بأسعار مختلفة تتناسب مع حجم المشروع وطلب العميل، وتشمل:
        المحلات التجارية والمنازل - المصانع والمعارض الكبري - شركات
        المقاولات والمباني - الجهات الحكومية والمؤسسات والهيئات العامة`,
        l3: 'أنظمة الأنذار ضد الحريق',
        pl3: `تقدم شركة سمارت اب نطاق واسع من منتجات إنذار ومكافحة الحريق
        مصنعة من قبل أفضل شركات أنظمة الحريق وتقدم أفضل الحلول
        المتكاملة مهما كانت المتطلبات من أي نظام تقليدي صغير الى أكبر
        حل تفاعلى متكامل، نحن لدينا الحل لنفي بإحتياجات عملائنا.`,
        l4: 'أنظمة التحكم في الدخول',
        pl4: `أنظمة التحكم في الدخول هي أجهزة أمن ترصد وتتحكم
        في الدخول إلى المنازل، الشقق أو أي مبنى ما. ونظرا لأن
        المفاتيح يسهل عمل نسخ منها، فإن هذه الأنظمة هي أفضل
        الطرق لتتبع من يدخل لمنطقتك.`,
        l5: 'أنظمة سنترال الهاتف',
        pl5: `هو نظام اتصال عن بعد يستخدم في الشبكة العامة لنظام الهاتف
        أو في المشاريع الكبيرة السنترال يتألف من مكونات إلكترونية وفي
        الأنظمة القديمة كان يستخدم العنصر البشري كتحويلة لربط خطوط هواتف
        المشتركين أو دوائر كهربائية في الأنظمة الرقمية لتنظيم المكالمات الهاتفية
        بين المشتركين.`,
        l6: 'أنظمة التلفاز الهوائي',
        pl6: `توفر شركة سمارت اب مجموعة كاملة من منتجات التلفاز الهوائي المركزي المصنعة من قبل
        الشركات العالمية ولنا خبرة واسعة في مجال تصميم وتصنيع وتسويق منتجات الارسال و
        الاستقبال وتوزيع إشارات التلفاز.`,
        l7: 'أنظمة IP TV',
        pl7: `توفر شركة سمارت اب انظمة ال IP TV  للتحكم الكامل بالنظام عن بعد
        دون الحاجة الى جهاز حاسب آلي مخصص ، تحسين عرض النطاق
        الترددي، وبث اللوحات النمطية فقط وفقا لطلب العميل وتسلم
        البرامج والخدمات كتيارات إرسال متعرجة سهل جدا واعداد نظام سهل
        الاستخدام.`,
        l8: 'أنظمة استدعاء الممرضات اللاسلكي',
        pl8: `تم تصميم نظام استدعاء الممرضات اللاسلكي لتنبيه فريق عمل التمريض للمكالمات الواردة من
        المرضى وغيرهم موظفين أخرين من فريق العمل ونظرا لأن نظام استدعاء الممرضين ھو نظام
        مؤسس على آشعة الراديو، فهو بالتأكيد يعتمد بشكل كبير وفق متطلباتك دون أي قيود تقليدية أو مشاكل ناتجة من النظام السلكي الثابت.`,
        l9: 'أنظمة مداخل وحواجز البوابات',
        pl9: `توفر شركة سمارت اب حلول المواقف حيث تستخدم البوابات الحاجزة عامة
        في مواقف السيارات والجراجات. كما أنها تستخدم في العديد من
        المؤسسات التجارية مثل المطارات والمرافق العامة ويمكن تشغيل هذه
        الوحدات تلقائيا عن طريق بطاقة القارئ، لوحة المفاتيح، نظام دخول هاتفي،أو
        يتحكم فيها يدويا`,
        l10: 'التشغيل التلقائي للمنازل',
        pl10: `توفر شركة اسمارت اب حلول أتمتة المنازل حيث انه نظام متخصص يتعامل مع متطلبات المنازل في استخدام التقنيات السهلة لأمن وراحة السكان. فهذا النظام يحتوي على كل ما يوفره
        التشغيل الآلي لمبني ما مثل الضوابط المناخية، التحكم في النوافذ والأبواب وتأمينهم وغيرها
        بالإضافة إلى مسارح الوسائط المتعددة للتحكم المنزلي، تغذية الحيوانات الأليفة، زراعة النباتات،
        الستائر الى اخره`,
        l11: 'نظام الساعات المركزية',
        pl11: `نظام الساعات المركزية هو ساعة دقيقة توفر إشارات توقيت مزامنه الساعات الأخرى كجزء من
        شبكة الساعات، ويتم التحكم في الساعة الرئيسية الموجودة مثل هذه التركيبات بواسطة مذبذب بلوري كوارتز عادة مايشار اليه بمعيار تردد خارجي مثل MSE 
        التى هي جزء من نظام ضبط الوقت في جميع انحاء العالم.`,
        l12: 'أنظمة مخاطبة الجمهور',
        pl12: `لدينا قائمة كبيرة من سابقات الاعمال فى المملكة العربية السعودية التي
        قمنا بتصميمها وتركيبها وصيانتها وتشمل المطارات، الفنادق، الجامعات،
        المستشفيات، المساجد وقاعات المؤتمرات وغيرها`,
        l13: 'أنظمة التحكم A/V',
        pl13: `يوفر نظام التحكم AV واجهة سهلة الاسخدام في شكل شاشة تعمل 
        باللمس أو لوحة زر، للسماح للمستخدم بالتحكم في كل عناصر هذا النظام
        من موقع واحد بدل من التحكم في كل جهاز بمفرده بواسطة عدة أجهزة تحكم
        عن بعد وسمارت اب سابقة مشاريع واسعة في هذا المجال.`,
        l14: 'أنظمة العرض',
        pl14: `النظام الكلي الذي يجمع بين الأجهزة والبرمجيات، وذلك لتحقيق التمثيل المرئي للمعلومات في نظام معالجة البيانات.`,
        l15: 'المؤتمرات الصوتية',
        pl15: `دائما ما تشير المؤتمرات الصوتية إلى الاجتماعات التي يعقدها الأشخاص في
        أماكن مختلفة باستخدام أجهزة تسمح بارسال الاصوات واستلامها ، وقد يشارك
        في هذه الأجتماعات طرفان فقط ولكن في كثير من الحالات هناك العديد من
        الأطراف المعنية حيث تسمح المؤتمرات الصوتية لعدة أطراف بالاتصال باستخدام
        أجهزه مثل الهواتف او أجهزة الحاسب.`,
        l16: 'مؤتمرات الفيديو',
        pl16: `تقنية مؤتمرات الفيديو هي إجراء عملية الاتصال من خلال الفيديو بواسطة تقنيات الاتصالات التي
        تتيح لمجموعة أو اثنين بالاتصال عن طريق البث الصوتى والفيديو ويتم استخدام تقنيات الاتصالات
        السمعية والبصرية لجمع الناس معا من مواقع مختلفة من مكاتبهم الخاصة أو لعديد من المواقع
        في غرف كبيرة متعددة`,
        l17: 'الفيديو الجداري',
        pl17: `تتكون هذه التكنولوجيا من شاشات حاسب آلي متعددة، أجهزة اسقاط صورة
        الفيديو، أو مجموعة من أجهزة التلفاز مجمعه سويا جنبا الى جنب بصورة متداخلة أو متتالية لتكون شاشة واحدة كبيرة وتشمل تقنيات العرض النموذجية لوحات LCD، صفائف DLP ,Tiles LED ، وشاشات الاسقاط الخلفي.`,
        l18: 'اللافتات الرقمية',
        pl18: `اللافتات الرقمية هي شكل من أشكال الشاشات الإلكترونية التي تعرض البرمجة
        التلفزيونية، القوائم، المعلومات الاعلانات، ورسائل أخرى، ويمكن إيجاد
        اللافتات الرقمية في كل من الأماكن العامة والخاصة بما فيها متاجر البيع
        بالتجزئة، الفنادق المطاعم مباني الشركات، وعدة أماكن أخرى.`,
        l19: 'أنظمة الأستوديو',
        pl19: `إن نظام الأستديو هو أداة تصميم وتحليل عالية الأداء مستندة الى نموذج معالجة خوارزمية فهو
        يضم أفضل أداء محاكاة في الصناعة بكفاءة نموذجية عالية تعتمد شركات الإتصالات والوسائط المتعددة على نظام الأستديو Synopsys والمكتبات النموذجية DSP لتلبية متطلبات مستوى النظام للوصول الى النتائج بصورة أسرع.`,


    },
    clients: {
        title: 'عملاؤنا',
        desc: 'تساعد شركتنا أي عملاء في أي مرحلة من مراحل تطوير الأعمال أو البرامج-من الشركات الناشئة في مرحلة التفكير إلى الشركات متوسطة الحجم والمؤسسات الكبيرة مع التركيز على التحسين والتوسع بغض النظر عن الصناعة التي يعملون فيها',
    },
    stats: {
        title: 'يقدم الخبراء ذوو الخبرة نصائح.',
        desc: 'قمنا بتسليم منصة لجمع معلومات سلوك المستخدم ، وبناء نماذج تنبؤية والمساعدة في اتخاذ قرارات تجارية مستنيرة.',
        p1: 'يزورنا العملاء كل شهر لإنجاز خدمتهم عبر موقعنا.',
        p2: 'معدل الرضا يأتي من عملائنا',
        p3: 'متوسط تصنيفات العملاء لدينا',
        m: '+ ١٠٠ الف',
    },
    teamwork: {
        title: 'فريق العمل',
        desc: 'فريق عمل بخبرات كبيرة في مجال البرمجة والتصميم, لدينا فريق عمل شغوف متخصص في أغلب المجالات التقنية والهندسية, فريق عمل يكتون من:',
        p1: 'مطورين ويب',
        p2: 'مسؤولين جودة',
        p3: 'مطورين واجهة الاستخدام',
        p4: 'مصممين واجهة الاستخدام',
        p5: 'مسؤولين التحليل',
        p6: 'فريق كامل للدعم والمراقبة'
    },
    contact: {
        title: 'تواصل معنا',
        desc: "أسئلتك وتعليقاتك مهمة بالنسبة لنا ، سنكون سعداء للإجابة على أسئلتك أو يمكنك الوصول إلينا عن طريق الهاتف أو البريد الإلكتروني.\n لدينا كل شيء مغطى لاحتياجاتك ",
        visit: 'موقعنا',
        address: 'الامير تركي بن عبدالعزيز الاول ، حطين',
        email: 'البريد الإلكتروني',
        call: 'اتصل بنا',
    },
    form: {
        f_l_name: 'الاسم الاول والاخير',
        f_l_name_h: 'مثلا محمد أحمد',
        email: 'البريد الإلكترونى',
        email_h: 'مثلا info@sair.sa',
        mobile: 'رقم الجوال',
        mobile_h: 'مثلا +966567890123',
        subj: 'الموضوع',
        subj_h: 'مثلا استفسار حول ...',
        msg: 'الرسالة',
        msg_h: 'مثلا كنت اود ان ...',
        send: 'إرسال',
        required: 'مطلوب',
        success: 'تم الإرسال بنجاح',
        error: 'حدث خطأ ما'
    },
    footer: {
        desc: 'شركة خيار الأمان هي شركة استشارية للإدارة والتكنولوجيا تساعد الشركات على إجراء تحسينات من خلال التكنولوجيا-خاصة في مجال برامج إدارة الأعمال.',
        comp: 'الشركة',
        about: 'عن الشركة',
        contact: 'تواصل معنا',
        carrers: 'الوظائف',
        press: 'اضغط',
        product: 'المنتج',
        features: 'الميزات',
        pricing: 'الاسعار',
        news: 'الاخبار',
        help: 'مكتب المساعدة',
        support: 'الدعم',
        services: 'الخدمات',
        digital: 'التسويق الرقمي',
        content: 'كتابة المحتوى',
        conent: 'كتابة المحتوى',
        seo: 'سيو للأعمال',
        ui: 'تصميم واجهة المستخدم',
        legal: 'قانوني',
        privacy: 'سياسة الخصوصية',
        terms: 'الشروط والأحكام',
        return: 'سياسة الإرجاع',
    }
}

export default ar;