import React from 'react';
import classNames from 'classnames';
import Image from '../../../elements/Image'
import i18next from 'i18next'

const LightCurrentServices = ({
    imageFill
}) => {
    return (
        <div>
            <div className="split-item">
                <div className="split-item-content center-content-mobile" data-reveal-container=".split-item">
                    <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                        
                        </div>
                    <h3 className="mt-0 mb-12">
                        {i18next.t('services.l1')}
                        </h3>
                    <p className="m-0">
                    {i18next.t('services.pl1')}
                        </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                    <Image
                        src={require('./../../../../assets/images/cable-solutions.jpg')}
                        alt="Features split 01"
                        width={528}
                        height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l2')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl2')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/CCTV-system.jpg')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l3')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl3')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/fire-alarm-system.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l4')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl4')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/access-control -systems.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l5')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl5')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/PABX-system.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l6')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl6')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/MATV-system.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l7')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl7')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/IP-TV-system.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l8')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl8')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/nurse-call-systems.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l9')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl9')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/gate-access-systems.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l10')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl10')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/home-automation-systems.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l11')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl11')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/master-clock-system.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l12')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl12')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/public-address-system.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l13')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl13')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/av-system.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l14')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl14')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/display-systems.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l15')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl15')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/audio-conference.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l16')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl16')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/video-conference.png')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l17')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl17')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/video-wall.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l18')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl18')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/billboard.png')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>

            <div className="split-item">
                <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                    
                    </div>
                <h3 className="mt-0 mb-12">
                    {i18next.t('services.l19')}
                    </h3>
                <p className="m-0">
                    {i18next.t('services.pl19')}
                    </p>
                </div>
                <div className={
                classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                    src={require('./../../../../assets/images/public-address-system.jpg')}
                    alt="Features split 03"
                    width={528}
                    height={396} />
                </div>
            </div>
        </div>
    )
}

export default LightCurrentServices
